
import Cookies from "js-cookie"

class MarketingService {
    constructor() {
        this.marketingInstance = null
        this.isInitialized = false
    }

    initialize() {
        if (Cookies.get("allowMarketingCookies") === "true" && typeof window !== "undefined") {
            this.isInitialized = true
        }
    }

    trackPage(path) {
        if (this.isInitialized) {
        }
        return true
    }

    trackEvent(event, data) {
        if (this.isInitialized) {
        }
        return true
    }
}

export default MarketingService